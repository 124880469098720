import { t } from "i18next";
import { LoadingIcon } from "../loading-icon";

interface IReverseButton {
  buttonText?: string | any;
  customStyle?: string;
  handleAction?: Function;
  disabled?: boolean;
  fullWidth?: boolean;
  id?: string;
  children?: any;
  type?: string;
  isLoading?: any;
}

export const ReverseButton = (props: IReverseButton) => {
  const {
    buttonText = "",
    customStyle = "",
    disabled = false,
    handleAction,
    fullWidth = false,
    id,
    // type, // Will have to use in the future, DO NOT DELETE
    children,
    isLoading = false,
  } = props;

  const action = () => {
    if (handleAction) return handleAction();
    return null;
  };

  const defaultStyle = `border-0 bg-primary text-[14px] font-black tracking-wider ${
    fullWidth && "w-full"
  }`;

  return (
    <button
      id={id}
      onClick={() => action()}
      disabled={disabled}
      className={
        customStyle
          ? `${customStyle} ${defaultStyle} ${disabled && "cursor-not-allowed"}`
          : `${defaultStyle} px-6 py-4`
      }
    >
      {isLoading && <LoadingIcon size={35} />}
      {!isLoading && t(buttonText)}
      {children}
    </button>
  );
};
