import React from "react";
import { t } from "i18next";

export const CouponTnC = (props: any) => {
  const { coupon } = props;

  const handleHtmlText = (text: any) => {
    return { __html: text };
  };

  if (!coupon) return null;

  return (
    <div className="max-w-[690px] sm:w-[690px]">
      {/* Coupon Title */}
      <h4 className="text-black tracking-[18px] pb-[18px]">
        {t(`coupon.tnc`)}
      </h4>

      {/* Video */}
      {(coupon.length > 0 && coupon[0].name) === "Re:Verse 預付卡" && (
        <div className="pb-[10px]">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/UXpxe_mCyt0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      )}

      {/* Coupon Terms & Conditions */}
      <ul className="pl-[24px]">
        {coupon.length > 0 &&
          coupon[0].how_to_use.map((redemption: any, i: number) => {
            return (
              <li
                key={i}
                className="leading-[40px] tracking-[2px] font-bold text-[14px] text-[#6F6F6F] text-left"
                dangerouslySetInnerHTML={handleHtmlText(redemption)}
              ></li>
            );
          })}
      </ul>
    </div>
  );
};
