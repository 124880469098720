import * as React from "react";
import { Router, Switch, Route, Redirect } from "react-router";
import { history } from "./stores";

// Components
import { Home } from "./pages/home";
import { Login } from "./pages/login";
import { Profile } from "./pages/profile";
import { CouponDetail } from "./pages/coupon-detail";
import { UseCoupon } from "./pages/use-coupon";
import { Wallet } from "./pages/wallet";
import { StripeCheckout } from "./pages/stripe-checkout";
import { CryptoCheckout } from "./pages/crypto-checkout";
import { Checkout } from "./pages/checkout";
import { Referral } from "./pages/referral";
import { Status } from "./pages/status";
import { Success } from "./pages/success";
import { Error } from "./pages/error";
import { AuthProvider } from "./components";
import { CheckoutAirwallet } from "./pages/checkout-airwallex";
import { CheckoutGcPay } from "./pages/checkout-gc-pay";

export default function Routers() {
  return (
    <Router history={history}>
      <Switch>
        <Route
          path={"/"}
          exact
          component={(props: any) => <Home {...props} />}
        />
        <Route
          path={"/login"}
          exact
          component={(props: any) => <Login {...props} />}
        />
        {/* Profile */}
        <Route
          path={"/profile"}
          exact
          component={(props: any) => (
            <AuthProvider>
              <Profile {...props} />
            </AuthProvider>
          )}
        />

        {/* Payment */}
        <Route
          path={"/payment/airwallex"}
          exact
          component={(props: any) => (
            <AuthProvider>
              <CheckoutAirwallet {...props} />
            </AuthProvider>
          )}
        />
        <Route
          path={"/payment/gc-pay"}
          exact
          component={(props: any) => (
            <AuthProvider>
              <CheckoutGcPay {...props} />
            </AuthProvider>
          )}
        />
        <Route
          path={"/payment/stripe"}
          exact
          component={(props: any) => (
            <AuthProvider>
              <StripeCheckout {...props} />
            </AuthProvider>
          )}
        />
        <Route
          path={"/payment/crypto"}
          exact
          component={(props: any) => (
            <AuthProvider>
              <CryptoCheckout {...props} />
            </AuthProvider>
          )}
        />

        {/* Payment Loading Page */}
        <Route
          path={"/status"}
          exact
          component={(props: any) => (
            <AuthProvider>
              <Status {...props} />
            </AuthProvider>
          )}
        />

        {/* Success Page */}
        <Route
          path={"/success"}
          exact
          component={(props: any) => (
            <AuthProvider>
              <Success {...props} />
            </AuthProvider>
          )}
        />

        {/* Error Page */}
        <Route
          path={"/error"}
          exact
          component={(props: any) => (
            <AuthProvider>
              <Error {...props} />
            </AuthProvider>
          )}
        />

        {/* Coupon */}
        <Route
          path={"/coupon/:id"}
          exact
          component={(props: any) => <CouponDetail {...props} />}
        />

        <Route
          path={"/checkout"}
          exact
          component={(props: any) => (
            <AuthProvider>
              <Checkout {...props} />
            </AuthProvider>
          )}
        />
        <Route
          path={"/referral"}
          exact
          component={(props: any) => (
            <AuthProvider>
              <Referral {...props} />
            </AuthProvider>
          )}
        />
        {/* Wallet */}
        <Route
          path={"/wallet"}
          exact
          component={(props: any) => (
            <AuthProvider>
              <Wallet {...props} />
            </AuthProvider>
          )}
        />
        <Route
          path={"/wallet/:id"}
          exact
          component={(props: any) => (
            <AuthProvider>
              <UseCoupon {...props} />
            </AuthProvider>
          )}
        />
        <Route path="*">
          <Redirect from={"/"} to={"/"} />
        </Route>
      </Switch>
    </Router>
  );
}
