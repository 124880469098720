import { t } from "i18next";
import React from "react";
import { addToCart, formatDollar } from "src/utils";
import { history } from "src/stores";
import { AddToCart } from "../addToCart";
import { ReverseButton } from "../button";

export const VerticalCoupon = (props: any) => {
  const { couponData } = props;

  return (
    <div className="relative w-full mx-auto mt-[20px] clear-both">
      <div className="flex flex-row justify-center flex-wrap sm:justify-start">
        {couponData.length > 0 &&
          couponData.map((item: any, i: any) => {
            return (
              <div
                key={i}
                className="overflow-hidden flex flex-col drop-shadow min-w-[290px] w-[100%] sm:w-[290px] h-[385px] mx-[10px] mt-[30px] cursor-pointer border-[1px] hover:border-primary"
              >
                {/* Coupon Hot Sale */}
                {item.available_stocks <= 10 && (
                  <div className="absolute top-0 right-0">
                    <img
                      className="w-10 block mr-auto ml-auto"
                      src={"/assets/hot-deal.png"}
                      alt="hot-deal"
                    />
                  </div>
                )}
                <div
                  className="h-full flex-1 border-primary border-b-[5px] py-5 group"
                  onClick={() => history.push(`/coupon/${item._id}`)}
                >
                  <img
                    src={item.logo || "/assets/icons/default-coupon.jpeg"}
                    className="object-cover w-[160px] h-[160px] rounded-full mx-auto duration-700 group-hover:scale-110"
                    alt="coupon-img"
                  />
                </div>
                <div className="flex flex-col text-left h-full flex-2 justify-between justify-between-md bg-white px-5 py-5">
                  <div onClick={() => history.push(`/coupon/${item._id}`)}>
                    <h3 className="text-black text-[16px] tracking-[1px] leading-[20px]">
                      {item.name}
                    </h3>

                    <h3 className="text-primary text-[20px] tracking-[2px] mr-2 mt-2 font-black">
                      {formatDollar(item.amount, "HKD")}
                    </h3>
                  </div>
                  <div className="flex flex-row justify-between items-center">
                    <h3 className="text-[12px] text-gray leading-[12px]">
                      {/* NEED TO UPDATE THE KEY FOR ALREADY PURCHASED */}
                      {item.purchased_stocks}
                      {t(`home.alreadyPurchased`)}
                    </h3>

                    {item.available_stocks === 0 ? (
                      <ReverseButton
                        disabled={item.available_stocks === 0}
                        buttonText={"coupon.couponOutOfStock"}
                        customStyle={`${
                          item.available_stocks === 0 && "warning"
                        } invisible absolute right-0 bottom-0 tracking-[8px] sm:!visible sm:w-[150px] px-6 py-4 `}
                        handleAction={() => addToCart(item._id, false, item)}
                        type="coupon"
                      />
                    ) : (
                      <AddToCart
                        id={item._id}
                        addToCart={() => addToCart(item._id, false, item)}
                      />
                    )}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
