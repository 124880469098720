import React, { useEffect, useMemo, useState } from "react";
import { Container, openErrorNotification } from "src/components";
import { Products } from "./products";
import { history } from "src/stores";
import { useCouponStore } from "../../stores";
import * as Configs from "../../config";

import { t } from "i18next";
import { getLocalStorage } from "src/utils";

const payments: any = [
  {
    image: "/assets/icons/payment/gc.png",
    method: "gc-pay",
    name: "Global Cash Pay",
    page: "/payment/gc-pay",
    btnStyle: "bg-[#333]",
    disabled: true,
  },
  {
    image: "/assets/icons/payment/visa.png",
    method: "airwallex",
    name: "Visa / Master",
    page: "/payment/airwallex",
    btnStyle: "bg-slate-600",
    disabled: false,
  },
  {
    image: "/assets/icons/payment/wechat.png",
    method: "airwallex",
    name: "Wechat Pay",
    page: "/payment/airwallex",
    btnStyle: "bg-[primary] ",
    disabled: false,
  },
  {
    image: "/assets/icons/payment/alipay.png",
    method: "airwallex",
    name: "Alipay",
    page: "/payment/airwallex",
    btnStyle: "bg-[#1C9BDD]/80 ",
    disabled: false,
  },
  {
    image: "/assets/icons/payment/stripe.png",
    method: "stripe",
    name: "Credit Card",
    page: "/payment/stripe",
    btnStyle: "bg-[#6566ED]/80 ",
    disabled: false,
  },
  {
    image: "/assets/icons/payment/metamask.png",
    method: "crypto",
    name: "Crypto Wallet",
    page: "/payment/crypto",
    btnStyle: "bg-[#FA5024]/70",
    disabled: false,
  },
];

export const Checkout = (props: any) => {
  const [checkoutData, setCheckoutData] = useState<any>([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [coupons, setCoupons] = useState<any>([]);

  const { getCoupons } = useCouponStore();

  useEffect(() => {
    const updateCheckoutItems = () => {
      setTimeout(() => {
        let checkoutItems: any = getLocalStorage("checkoutItems");
        //When identify cart is empty, redirect to "/"
        if (checkoutItems.length === 0) {
          setCheckoutData([]);
          return history.push("/");
        }

        if (checkoutItems.length > 0 && coupons.length > 0) {
          checkoutItems = checkoutItems.map((checkoutItem: any) => {
            const matchItems = coupons.find(
              (item: any) => item["_id"] === checkoutItem["_id"]
            );
            return {
              ...matchItems,
              quantity: checkoutItem["quantity"],
            };
          });
          setCheckoutData(checkoutItems);
        }
      }, 100);
    };
    window.addEventListener("storage", updateCheckoutItems);
    return () => {
      window.removeEventListener("storage", updateCheckoutItems);
    };
  }, [coupons, setCheckoutData]);

  //   init the data from the localStorage
  useEffect(() => {
    getCoupons("").then((result) => {
      let checkoutItems = getLocalStorage("checkoutItems");
      if (checkoutItems) {
        checkoutItems = checkoutItems.map((checkoutItem: any) => {
          const matchItems = result.find(
            (item: any) => item["_id"] === checkoutItem["_id"]
          );
          return {
            ...matchItems,
            quantity: checkoutItem["quantity"],
          };
        });
        setCheckoutData(checkoutItems);
        setCoupons(result);
      }
    });
  }, [getCoupons, setCoupons]);

  useEffect(() => {
    let accPrice = 0;
    checkoutData &&
      checkoutData.length > 0 &&
      checkoutData.map((data: any, index: number) => {
        const { amount: price, quantity = 0 } = data;
        accPrice += quantity * parseInt(price);
        return setTotalPrice(accPrice);
      });
  }, [checkoutData, setTotalPrice]);

  const handlePayment = (props: any) => {
    const { method, page = "/", disabled, name } = props;
    let formattedPrice = totalPrice;
    let fee = 0;
    if (!method || disabled) return;
    switch (method) {
      case "paypal":
        return false;
      case "gc-pay":
      case "crypto":
      case "airwallex":
      case "stripe":
        formattedPrice = totalPrice + totalPrice * 0.05;
        fee = totalPrice * 0.05;
        break;
    }
    if (!checkoutData || checkoutData.length === 0) {
      return openErrorNotification("無法獲取你的選購貨品，新重新嘗試。");
    }
    //Create Payment Order
    const couponData = {
      amount: formattedPrice,
      currency: "HKD",
      method: method,
      checkout_items: [...checkoutData],
      fee,
      name,
    };
    history.push({ pathname: `${page}`, state: { ...couponData } });
  };

  const disabledStriptButton = useMemo(() => {
    let disabled = false;
    for (let data of checkoutData) {
      if (Configs.acceptMechantId.indexOf(data.merchant_id) === 0) {
        return true;
      }
    }
    return disabled;
  }, [checkoutData]);

  const isOnlyGcPay = useMemo(() => {
    for (const coupon of coupons) {
      if (Configs.gcPayConfig.merchantList.includes(coupon["_id"])) {
        return true;
      }
    }
    return false;
  }, [coupons]);

  return (
    <Container>
      <div className="flex flex-col items-center px-10 pt-20">
        <h4 className="mb-10 tracking-widest">{t("checkout.title")}</h4>

        <Products
          totalPrice={totalPrice}
          checkoutData={checkoutData}
          setCheckoutData={setCheckoutData}
        />

        {/* Payment Methods */}
        <h4 className="mt-10 tracking-widest ml-[5px]">
          {t("checkout.paymentTitle")}
        </h4>
        <div className="flex flex-col w-[350px] mt-[20px] justify-center">
          {payments &&
            payments.length > 0 &&
            payments.map((payment: any, index: number) => {
              let { image, method, disabled, name, btnStyle, txtStyle } =
                payment;
              let buttonDisabled = disabled;
              if (method === "stripe" && disabledStriptButton) {
                buttonDisabled = disabledStriptButton;
              }

              if (isOnlyGcPay && method !== "gc-pay") return <></>;
              if (isOnlyGcPay && method === "gc-pay") {
                buttonDisabled = false;
                payment["disabled"] = false;
              }

              return (
                <button
                  key={index}
                  onClick={() => handlePayment(payment)}
                  className={`w-[100%] text-[14px] rounded-[5px] min-h-[50px] flex flex-row items-center mb-[20px] relative ${
                    buttonDisabled
                      ? `cursor-not-allowed opacity-60`
                      : `cursor-pointer`
                  } ${btnStyle || ""}`}
                >
                  <p
                    className={`text-white uppercase tracking-[5px] ${
                      txtStyle || ""
                    }`}
                  >
                    {name || method}
                  </p>
                  <img
                    alt="payment"
                    className={`w-[30px] object-contain absolute top-[8px] right-[20px]`}
                    src={image}
                  />
                </button>
              );
            })}
        </div>
      </div>
    </Container>
  );
};
