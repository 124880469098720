import React, { useCallback, useEffect, useState } from "react";

import {
  Container,
  LoadingIcon,
  openErrorNotification,
  openNotificationWithIcon,
  ReverseButton,
} from "src/components";
import { useTransactionStore } from "src/stores";
import { useLocation } from "react-router-dom";
import { history } from "src/stores";
import { t } from "i18next";
import { setLocalStorage } from "src/utils";
import { AIRWALLET_URL } from "src/config";

export const CheckoutAirwallet = (props: any) => {
  const location = useLocation();
  const data: any = location.state;
  const { createPaymentOrder, updatePaymentOrder, checkPaymentOrder } =
    useTransactionStore();

  const [transaction, setTransaction] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [issued, setIssued] = useState(false);

  const approveOrder = useCallback(
    async (id: any) => {
      setLoading(true);
      const payment = await updatePaymentOrder({
        orderId: id,
        status: "succeed",
      });
      setLoading(false);
      setIssued(true);
      if (!payment) {
        openErrorNotification("付款失敗，請重新嘗試。");
        return history.push("/error");
      }
      switch (payment["status"]) {
        case "succeed":
          //Clear local storage if payment success
          setLocalStorage("checkoutItems", []);
          return history.push("/success");
        default:
          openErrorNotification(t("付款失敗，請重新嘗試。"));
          return history.push("/");
      }
    },
    [updatePaymentOrder]
  );

  const checkPaymentCb = useCallback(
    async (autoFetch = false) => {
      if (loading) return;
      if (!transaction["order_id"]) return;
      setLoading(true);
      const order = await checkPaymentOrder({
        orderId: transaction["order_id"],
      });
      const status = order["status"];
      switch (status) {
        case "REQUIRES_PAYMENT_METHOD": // waiting for payment
          !autoFetch &&
            openNotificationWithIcon(
              "warning",
              t("messages.warning"),
              "請確認你已經付款。"
            );
          break;
        case "SUCCEEDED": // success
          await approveOrder(transaction["order_id"]);
          break;
        case "CANCELLED": // expired
          await updatePaymentOrder({
            orderId: transaction["order_id"],
            status: "cancelled",
          });
          openNotificationWithIcon(
            "warning",
            t("messages.warning"),
            "已取消付款"
          );
          break;
      }
      setLoading(false);
    },
    [approveOrder, checkPaymentOrder, loading, transaction, updatePaymentOrder]
  );

  const openAirWallexCb = useCallback((paymentDetails) => {
    if (!paymentDetails.id || !paymentDetails.client_secret) return;
    window.open(
      `${AIRWALLET_URL}/?id=${paymentDetails.id}&client_secret=${paymentDetails.client_secret}&currency=HKD`
    );
  }, []);

  const createPaymentOrderByMethod = useCallback(async () => {
    const payload = {
      amount: String(data.amount),
      currency: "HKD",
      method: "airwallex",
      checkout_items: data.checkout_items,
    };
    setLoading(true);
    let paymentDetails: any = await createPaymentOrder(payload);
    setLoading(false);
    setTransaction(paymentDetails);
    openAirWallexCb(paymentDetails);
  }, [createPaymentOrder, setTransaction, data, openAirWallexCb]);

  useEffect(() => {
    if (!data) return;
    createPaymentOrderByMethod();
  }, [data, createPaymentOrderByMethod]);

  useEffect(() => {
    if (loading) return;
    if (!transaction) return;
    const interval: any = setInterval(() => {
      if (issued) return clearInterval(interval);
      checkPaymentCb(true);
    }, 5000);
    return () => clearInterval(interval);
  }, [loading, transaction, issued, checkPaymentCb]);

  return (
    <Container>
      <div className="w-[auto] sm:w-[550px] h-auto m-auto">
        <div className="p-12">
          <h4 className="text-[16px] !mb-0 text-center tracking-[18px]">
            {data.name}
          </h4>

          <div className="flex flex-col items-center">
            <h4
              className={`mt-[40px] tracking-wider text-primary text-base font-semibold`}
            >
              {t("payment.doNotRefresh")}
            </h4>
            <img
              src="/assets/cashless-payment.png"
              className="w-[120px] my-[40px] object-fit"
              alt="pay-icon"
            />

            {loading && (
              <div className="flex justify-center items-center">
                <LoadingIcon />
              </div>
            )}

            {!loading && (
              <>
                <ReverseButton
                  fullWidth={true}
                  customStyle={"py-[10px] rounded-[5px]"}
                  handleAction={() => openAirWallexCb(transaction)}
                >
                  前往付款 HKD ${data.amount - data.fee} + ($
                  {data.fee})
                </ReverseButton>
                <ReverseButton
                  handleAction={() => checkPaymentCb(false)}
                  fullWidth={true}
                  buttonText={"我已付款"}
                  customStyle={`mt-3.5 pl-0 px-[5px] py-[8px] sm:text-sm focus:ring-1 sm:text-[12px] tracking-wider text-primary bg-white border border-primary`}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};
