import { t } from "i18next";
import React, { useState } from "react";
import moment from "moment";
import { addToCart, formatDollar } from "src/utils";
import { ReverseButton, openWarningNotification } from "../";
import { history } from "src/stores";
import { AddToCart } from "../addToCart";
import { ShowCouponModal } from "./show-coupon-modal";

export const HorizontalCoupons = (props: any) => {
  const { couponData, allowRedeem = false, fromPage } = props;

  const [openModal, setOpenModal] = useState(false);
  if (!couponData || couponData.length === 0) return null;

  const handleButton = (item: any, redeemable: boolean) => {
    if (!redeemable) {
      //Add to cart
      if (item.available_stocks === 0)
        return openWarningNotification(t("messages.couponOutOfStock"));
      return addToCart(item["_id"], true, item);
    }
    setOpenModal(true);
  };

  const getWrapperStyle = () => {
    if (!fromPage) return "";
    return "flex flex-col sm:!flex-row scroll-smooth overflow-x-auto";
  };

  const getCouponStyle = () => {
    if (!fromPage) return "";
    return "mr-0 pb-5 sm:mr-5 sm:pb-0";
  };

  const formatButtonTxt = (stock?: any) => {
    if (stock === 0) return "coupon.couponOutOfStock";
    if (!allowRedeem) return "home.buyNow";
    return "redeemNow";
  };

  return (
    <div
      className={`relative justify-start sm:justify-items-center ${getWrapperStyle()}`}
    >
      {couponData.length > 0 &&
        couponData.map((item: any, i: any) => {
          return (
            <div
              key={i}
              className={`${getCouponStyle()} overflow-hidden mb-10 flex flex-1 flex-col sm:!flex-row drop-shadow md:h-[195px] cursor-pointer border-[1px] max-w-[500px] sm:max-w-[650px] min-w-[auto] sm:min-w-[600px] duration-700 hover:border-primary`}
            >
              {/* Coupon Hot Sale */}
              {item.available_stocks <= 10 && (
                <div className="absolute top-0 right-0">
                  <img
                    className="w-10 block mr-auto ml-auto"
                    src={"/assets/hot-deal.png"}
                    alt="hot-deal"
                  />
                </div>
              )}

              <div
                onClick={() =>
                  fromPage !== "redeem" && history.push(`/coupon/${item._id}`)
                }
                className="h-full flex-1 border-[1px] border-slate-50"
              >
                <img
                  src={item.logo || "/assets/icons/default-coupon.jpeg"}
                  className="max-md:w-full md:w-[215px] h-[191px] object-cover"
                  alt="coupon-img"
                />
              </div>
              <div className="flex flex-col text-left h-full flex-2 justify-between justify-between-md bg-white px-3 pt-3">
                <h3
                  className="text-black text-[16px] leading-6"
                  onClick={() =>
                    fromPage !== "redeem" && history.push(`/coupon/${item._id}`)
                  }
                >
                  {item.name}
                </h3>

                {!allowRedeem && (
                  <div className="mt-5">
                    <div>
                      {item.face_value !== item.amount && (
                        <span className="text-[#EF637C] text-[12px] tracking-[5px] line-through font-black">
                          {formatDollar(item.face_value, "HKD")}
                        </span>
                      )}
                      <h3 className="text-primary text-[16px] tracking-[5px] font-black leading-[25px]">
                        {formatDollar(item.amount, "HKD")}
                      </h3>
                    </div>
                  </div>
                )}

                <div className={`flex flex-row items-center pt-3`}>
                  {/* General Coupon */}
                  {!allowRedeem && (
                    <>
                      <h3 className="text-[12px] text-gray pr-10">
                        {item.purchased_stocks}
                        {t(`home.alreadyPurchased`)}
                      </h3>
                      <AddToCart
                        addToCart={() => addToCart(item._id, false, item)}
                      />
                    </>
                  )}

                  {/* Redemption Coupon */}
                  {allowRedeem && (
                    <div className="min-w-[110px]">
                      <h3 className=" tracking-[2px] leading-[20px] text-[12px] text-gray">
                        {t(`coupon.expiryDate`)}
                      </h3>
                      <h3 className="pb-2 tracking-[2px] leading-[20px] text-[12px] text-gray">
                        <span>
                          {!item.expiry_date
                            ? "n/a"
                            : moment(item.expiry_date).format("DD/MM/YYYY")}
                        </span>
                      </h3>
                    </div>
                  )}
                  <ReverseButton
                    disabled={item.available_stocks === 0}
                    buttonText={formatButtonTxt(item.available_stocks)}
                    customStyle={`${
                      item.available_stocks === 0 && "warning"
                    } invisible absolute right-0 bottom-0 tracking-[8px] sm:!visible sm:w-[150px] px-6 py-4 `}
                    handleAction={() => handleButton(item, allowRedeem)}
                    type="coupon"
                  />

                  {/* Coupon Modal */}
                  <ShowCouponModal
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    coupon={item}
                  />
                </div>
              </div>

              <ReverseButton
                buttonText={!allowRedeem ? `home.buyNow` : `redeemNow`}
                customStyle={`${
                  item.available_stocks === 0 && "bg-[#F5D560]"
                } w-[100%] sm:hidden block relative tracking-[8px] px-6 py-4`}
                handleAction={() => handleButton(item, allowRedeem)}
                type="coupon"
              />
            </div>
          );
        })}
    </div>
  );
};
